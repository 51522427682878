import React from "react";
import { CardSensor } from "./CardSensor/CardSensor";

export const TerminalDeviceBatteryAndSolarSensorView = (props) => {
  const { terminalDevice } = props;

  return (
    <div className="sensorsCardFather">
      {terminalDevice?.batteryVoltage !== undefined && 
      <CardSensor
        key={0}
        description={"Battery"}
        physicalCommunicationType={"ADC"}
        id={0}
        sensorIndex={0}
        sensorValue={terminalDevice?.batteryVoltage}
        sensorUnit={"V"}
        loading={false}
        dateTime={
          terminalDevice.lastStatus && new Date(terminalDevice.lastStatus)
        }
        onClick={() => {}}
        allowSwitchToAverage={false}
      />}
      {terminalDevice?.solarPanelVoltage !== undefined && 
      <CardSensor
        key={1}
        description={"Panel"}
        physicalCommunicationType={"ADC"}
        id={1}
        sensorIndex={1}
        sensorValue={terminalDevice?.solarPanelVoltage}
        sensorUnit={"V"}
        loading={false}
        dateTime={
          terminalDevice.lastStatus && new Date(terminalDevice.lastStatus)
        }
        onClick={() => {}}
        allowSwitchToAverage={false}
      />}
    </div>
  );
};
