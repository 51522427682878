import React, { useEffect, useState, useCallback } from "react";
import { useSubscriber } from "../../../context/subscriber-context";
import { projectsConstants } from "../../../_constants/projects.constants";
import {
  MESSAGE_TYPE_NOTIFICATION,
  MESSAGE_TYPE_ERROR,
} from "../../../_constants/messageType.constants";

export const MqttMessageProcessor = (props) => {
  const { topic, processMsg, processErrorMsg, project } = props;
  const [lastmessageId, setlastmessageId] = useState(undefined);
  const [lastErrorMessageId, setlastErrorMessageId] = useState(undefined);
  const [init, setinit] = useState(false);
  const {
    actoutputMsg,
    advancedActoutputMsg,
    endoutputMsg,
    updatestateMsg,
    updateoutputsMsg,
    getonlineterminalsMsg,
    setworkmodeMsg,
    maintenanceMsg,
    setoutputvMsg,
    getsensorvalueMsg,
    settasksbyidMsg,
    deletetasksMsg,

    setTriggersMsg,
    deleteTriggersMsg,
    reportSensorsMsg,
    sensorsConfigMsg,

    tsmGetSensorValueMsg,
    tsmGetSensorStatusMsg,
    tsmUpdatestatessMsg,
    tsmCalibrateSensorMsg,
    tsmGetStatesMsg,
    tsmCalibrationReportMsg,

    specConfig,
    specCalibration,

    loraActoutputMsg,
    loraEndoutputMsg,
    loraSettriggersMsg
  } = useSubscriber();

  const isMsgAvailable = useCallback((message, lastMessageId) => {
    return (
      message?.topic !== undefined && message?.message.id !== lastMessageId
    );
  }, []);

  const isErrorMsgAvailable = useCallback(
    (message, lastErrorMessageId) => {
      return isMsgAvailable(message, lastErrorMessageId);
    },
    [isMsgAvailable]
  );

  const getMsg = useCallback(
    (topic, project) => {
      if (project === projectsConstants.postigel.id) {
        switch (topic) {
          case projectsConstants.postigel.actions.specconfig:
            return specConfig;
          case projectsConstants.postigel.actions.speccalibration:
            return specCalibration;
          default:
            return undefined;
        }
      } 
      else if(project === projectsConstants.lora_outputs.id){
        switch(topic){
          case projectsConstants.lora_outputs.actions.actoutput:
            return loraActoutputMsg
          case projectsConstants.lora_outputs.actions.endoutput:
            return loraEndoutputMsg
            case projectsConstants.lora_outputs.actions.settriggers:
            return loraSettriggersMsg;
          default:
            break;
        }
      }
      else {
        switch (topic) {
          case projectsConstants.master_outputs.actions.actoutput:
            return actoutputMsg;
          case projectsConstants.master_outputs.actions.advanced_actoutput:
            return advancedActoutputMsg;
          case projectsConstants.master_outputs.actions.endoutput:
            return endoutputMsg;
          case projectsConstants.master_outputs.actions.updatesstate:
            return updatestateMsg;
          case projectsConstants.master_outputs.actions.updateoutputs:
            return updateoutputsMsg;
          case projectsConstants.master_outputs.actions.getonlineterminals:
            return getonlineterminalsMsg;
          case projectsConstants.master_outputs.actions.setworkmode:
            return setworkmodeMsg;
          case projectsConstants.master_outputs.actions.maintenance:
            return maintenanceMsg;
          case projectsConstants.master_outputs.actions.setoutputv:
            return setoutputvMsg;
          case projectsConstants.master_outputs.actions.getsensorvalue:
            return getsensorvalueMsg;
          case projectsConstants.master_outputs.actions.settasksbyid:
            return settasksbyidMsg;
          case projectsConstants.master_outputs.actions.deletetasks:
            return deletetasksMsg;

          case projectsConstants.master_outputs.actions.common.settriggers:
            return setTriggersMsg;
          case projectsConstants.master_outputs.actions.common.deletetriggers:
            return deleteTriggersMsg;
          case projectsConstants.master_outputs.actions.common.reportsensors:
            return reportSensorsMsg;
          case projectsConstants.master_outputs.actions.common.sensorsconfig:
            return sensorsConfigMsg;

          case projectsConstants.master_outputs.actions.tsm.tsm_getsensorvalue:
            return tsmGetSensorValueMsg;
          case projectsConstants.master_outputs.actions.tsm.tsm_getsensorstatus:
            return tsmGetSensorStatusMsg;
          case projectsConstants.master_outputs.actions.tsm.tsm_updatestates:
            return tsmUpdatestatessMsg;
          case projectsConstants.master_outputs.actions.tsm.tsm_calibratesensor:
            return tsmCalibrateSensorMsg;
          case projectsConstants.master_outputs.actions.tsm.tsm_getstates:
            return tsmGetStatesMsg;
          case projectsConstants.master_outputs.actions.tsm
            .tsm_calibrationreport:
            return tsmCalibrationReportMsg;
          default:
            return reportSensorsMsg;
        }
      }
    },
    [
      actoutputMsg,
      advancedActoutputMsg,
      deleteTriggersMsg,
      deletetasksMsg,
      endoutputMsg,
      getonlineterminalsMsg,
      getsensorvalueMsg,
      maintenanceMsg,
      reportSensorsMsg,
      sensorsConfigMsg,
      setTriggersMsg,
      setoutputvMsg,
      settasksbyidMsg,
      setworkmodeMsg,
      specCalibration,
      specConfig,
      tsmCalibrateSensorMsg,
      tsmCalibrationReportMsg,
      tsmGetSensorStatusMsg,
      tsmGetSensorValueMsg,
      tsmGetStatesMsg,
      tsmUpdatestatessMsg,
      updateoutputsMsg,
      updatestateMsg,
      loraActoutputMsg,
      loraEndoutputMsg,
      loraSettriggersMsg
    ]
  );

  useEffect(() => {
    let msg = getMsg(topic, project);

    if (msg) {
      msg = {
        topic: msg.topic,
        message: JSON.parse(msg.message),
      };
    }

    let type = msg?.message?.type;
    if (
      type === MESSAGE_TYPE_NOTIFICATION &&
      isMsgAvailable(msg, lastmessageId)
    ) {
      setlastmessageId(msg.message.id);
      if (init) {
        processMsg(msg.message.id, msg.message);
      }
    } else if (
      type === MESSAGE_TYPE_ERROR &&
      isErrorMsgAvailable(msg, lastErrorMessageId)
    ) {
      setlastErrorMessageId(msg.message.id);
      if (props.processErrorMsg !== undefined) {
        if (init) {
          processErrorMsg(msg.message.id, msg.message);
        }
      }
    } else if (
      type !== undefined &&
      type !== MESSAGE_TYPE_NOTIFICATION &&
      type !== MESSAGE_TYPE_ERROR
    ) {
      console.log("Tipo de mensaje no soportado -> " + type);
    }

    // Con init controlamos que mensajes recibidos antes de montar el componente
    // no sean procesados.
    setinit(true);
  }, [
    actoutputMsg,
    advancedActoutputMsg,
    endoutputMsg,
    updatestateMsg,
    updateoutputsMsg,
    getonlineterminalsMsg,
    setworkmodeMsg,
    maintenanceMsg,
    setoutputvMsg,
    getsensorvalueMsg,
    settasksbyidMsg,
    specConfig,
    specCalibration,
    setTriggersMsg,
    deleteTriggersMsg,
    reportSensorsMsg,
    sensorsConfigMsg,
    tsmGetSensorValueMsg,
    tsmGetSensorStatusMsg,
    tsmUpdatestatessMsg,
    tsmCalibrateSensorMsg,
    tsmGetStatesMsg,
    tsmCalibrationReportMsg,
    loraActoutputMsg,
    loraEndoutputMsg,
    loraSettriggersMsg,
    topic,
    processMsg,
    processErrorMsg,
    project,
    getMsg,
    lastmessageId,
    isErrorMsgAvailable,
    lastErrorMessageId,
    init,
    props.processErrorMsg,
    isMsgAvailable,
  ]);

  return <></>;
};
