import React, { useEffect, useCallback, useState } from "react";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import { getFullDateTimeFormatted } from "../../../../../_helpers/DateFormatHelper";
import { Spinner } from "../../../Airframe/Spinner/Spinner";
import { Humidity } from "../../../Icon/Humidity";
import { Preassure } from "../../../Icon/Preassure";
import { Temperature } from "../../../Icon/Temperature";

import "./CardSensor.sass";
import { Average } from "../../../Icon/Average";
import { Live } from "../../../Icon/Live";
import { Badge } from "reactstrap";

export const getUnitInfo = (sensorUnit) => {
  for (let unit in projectsConstants.global.sensors.units) {
    if (unit === sensorUnit) {
      return projectsConstants.global.sensors.units[unit];
    }
  }
};

const dataMode = {
  NORMAL: "NORMAL",
  AVERAGE: "AVERAGE"
}

export const CardSensor = (props) => {
  const {
    id,
    sensorIndex,
    physicalCommunicationType,
    sensorUnit,
    sensorValue,
    dateTime,
    onClick,
    loading,
    labelPrefix,
    allowSwitchToAverage,
    dialyAverageSensorValue,
    dailyMinSensorValue,
    dailyMaxSensorValue,
    description
  } = props;

  const [showDataMode, setShowDataMode] = useState(dataMode.NORMAL)

  const getSensorIndexLabel = useCallback(() => {
    return description || `${labelPrefix || 
      (physicalCommunicationType === projectsConstants.global.sensors.phys.cuds
        ? "Caud."
        : "S")
    }${sensorIndex + 1}`;
  }, [labelPrefix, physicalCommunicationType, sensorIndex, description]);

  const onDivClick = useCallback(() => {
    if (onClick) {
      onClick(sensorIndex);
    }
  }, [onClick, sensorIndex]);

  const getShowDataIcon = useCallback(() => {
    if(showDataMode === dataMode.NORMAL){
      return <Average onClick={() => { setShowDataMode(dataMode.AVERAGE) }}/>
    }
    
    return <Live onClick={() => { setShowDataMode(dataMode.NORMAL) }}/>
  }, [showDataMode])

  const getCommonMeasureComponent = useCallback((mainUnit, mainValue, secondaryUnit, secondaryValue) => {
    return <>
      <div style={{display: "flex", flexFlow: "wrap", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
        <p className= "sizesensorsText" style={{margin: "0px", lineHeight: "1"}}>{mainValue}</p>
        <p className= "sizesensorsSubText" style={{ fontSize: "0.8'rem", margin: "0px", lineHeight: 1}}>{`${mainUnit}`}</p>
      </div>
      <div style={{display: "flex", flexFlow: "wrap", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
        {!isNaN(secondaryValue) && <p className="subText" style={{margin: "0px", lineHeight: "1"}}>{secondaryValue}</p>}
        {!isNaN(secondaryValue) && <p className="subText" style={{margin: "0px", lineHeight: "1"}}>{secondaryUnit}</p>}
      </div>
    </>
  }, [])

  const getNormalComponent = useCallback(() => {
    return getCommonMeasureComponent(sensorUnit, sensorValue?.toFixed(2), `/día`, dialyAverageSensorValue?.toFixed(2))
  }, [dialyAverageSensorValue, getCommonMeasureComponent, sensorUnit, sensorValue])

  const getAverageComponent = useCallback(() => {
    return getCommonMeasureComponent(`${sensorUnit}/día`, dialyAverageSensorValue?.toFixed(2), sensorUnit, sensorValue?.toFixed(2))
  }, [dialyAverageSensorValue, getCommonMeasureComponent, sensorUnit, sensorValue])

  const getMeasureComponent = useCallback(() => {
    if(loading){
      return <Spinner />
    }

    if(showDataMode === dataMode.AVERAGE){
      return getAverageComponent()
    }

    return getNormalComponent()

  }, [getAverageComponent, getNormalComponent, loading, showDataMode])

  return (
      <div
        key={id}
        className={"alarmDesactivate_SensorCard"}
      >
        <div className="Title_Descripction SensorShow">
          <div className="card-body-sensor">
            <div className="titlecardsensor">
              <div className="titlecardsensorname">{getSensorIndexLabel()}</div>
              {allowSwitchToAverage && getShowDataIcon()}
              
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
              <Badge color="primary">
                  {dailyMinSensorValue?.toFixed(2)}
              </Badge>

              <Badge color="danger">
                  {dailyMaxSensorValue?.toFixed(2)}
              </Badge>
            </div>
            <div className="imagesensorandtext" onClick={onDivClick}>
              <div className="sensordescription">
                {getMeasureComponent()}
              </div>
            </div>
            <div
              style={{
                fontSize: "0.5rem",
                display: "flex",
                justifyContent: "right",
              }}
            >
              {dateTime ? getFullDateTimeFormatted(dateTime) : " -- "}
            </div>
          </div>
        </div>
      </div>
  );
};
